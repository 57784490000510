import { TOAST_CONFIG, Toasts } from '@bd/components'
import { i18n } from '@bd/agent/plugins/vue-i18n-next-plugin'

const { t } = i18n.global
const { messageDuration } = TOAST_CONFIG

export const AddClientMessages = Toasts({
  saveSuccess: {
    severity: 'success',
    summary: t('clients.messages.successSave'),
    life: messageDuration.success,
  },
  saveError: {
    severity: 'error',
    summary: t('clients.messages.errorSave'),
    life: messageDuration.error,
  },
})
