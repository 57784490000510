
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import {
  TextInput,
  NumberInput,
  PageContent,
  TextareaInput,
  SelectSingleButton,
  useRootData,
  clientValidationService,
} from '@bd/components'
import { useForm } from 'vee-validate'
import { addClientSchema } from '@bd/components/yup'
import { LOCALISATION_SETTINGS } from '@bd/agent/config'
import { useAppStore } from '@bd/agent/store'
import { CustomerDto } from '@bd/api'
import { useToast } from 'primevue/usetoast'
import { AddClientMessages } from '@bd/agent/config/toastMessages'

export default defineComponent({
  name: 'AddClient',
  components: {
    PageContent,
    TextInput,
    NumberInput,
    TextareaInput,
    SelectSingleButton,
  },
  setup() {
    const i18n = useI18n()
    const store = useAppStore()
    const router = useRouter()
    const { isLoading } = useRootData()
    const toast = useToast()

    const form = useForm<Partial<CustomerDto>>({
      validationSchema: addClientSchema as never,
    })

    const onSubmit = form.handleSubmit(async (form, context) => {
      try {
        await store.dispatch('clients/addClient', form as CustomerDto)
        await router.push({ name: 'Dashboard' })
        toast.add(AddClientMessages.saveSuccess)
      } catch (error) {
        const errorsData = clientValidationService.handleValidation(error)
        errorsData && context.setErrors(errorsData)
        toast.add(AddClientMessages.saveError)
      }
    })

    const marketOptions = [
      { name: i18n.t('clients.form.primary'), value: 'PRIMARY' },
      {
        name: i18n.t('clients.form.secondary'),
        value: 'SECONDARY',
      },
    ]

    return {
      ...i18n,
      ...LOCALISATION_SETTINGS,
      addClientSchema,
      onSubmit,
      marketOptions,
      isLoading,
    }
  },
})
