<template>
  <PageContent
    :showMobileHeader="true"
    :headerLabel="t('clients.adding')"
    class="custom-page-content"
  >
    <div class="m-0">
      <div class="form-content">
        <div class="container">
          <form novalidate @submit="onSubmit">
            <div class="form-header">
              <div class="h-1">{{ t('clients.adding') }}</div>
              <div>
                <Button
                  :disabled="isLoading"
                  type="submit"
                  class="p-button-primary px-4"
                  :label="t('clients.add')"
                />
              </div>
            </div>
            <div class="form-wrapper">
              <section class="form-section">
                <div class="inner-spacing">
                  <span class="h-4">{{ t('clients.form.personal_data') }}</span>
                  <div class="p-fluid">
                    <TextInput
                      name="description"
                      type="text"
                      :label="t('clients.form.client_description')"
                      :floating="false"
                    />
                  </div>
                  <div class="p-fluid">
                    <TextInput
                      name="firstName"
                      type="text"
                      :label="t('first_name')"
                      :floating="false"
                    />
                  </div>
                  <div class="p-fluid">
                    <TextInput
                      name="lastName"
                      type="text"
                      :label="t('last_name')"
                      :floating="false"
                    />
                  </div>
                  <div class="p-fluid">
                    <TextInput
                      name="phoneNumber"
                      type="text"
                      :label="t('phone')"
                      :floating="false"
                    />
                  </div>
                </div>
                <div class="inner-spacing">
                  <span class="h-4">{{
                    t('clients.form.wanted_apartment')
                  }}</span>
                  <div class="p-fluid">
                    <TextInput
                      name="city"
                      type="text"
                      :label="t('city')"
                      :floating="false"
                    />
                  </div>
                  <div class="p-fluid">
                    <TextInput
                      name="district"
                      type="text"
                      :label="t('district')"
                      :floating="false"
                    />
                  </div>
                </div>
                <div class="inner-spacing">
                  <span class="h-4">{{ t('clients.form.area') }}</span>
                  <div class="row mt-4">
                    <div class="col-6">
                      <div class="p-fluid">
                        <span class="h-5">{{ t('land_area_from') }}</span>
                        <NumberInput
                          :floating="false"
                          name="areaMin"
                          placeholder="m2"
                          label=""
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <span class="h-5">{{ t('land_area_to') }}</span>
                      <div class="p-fluid">
                        <NumberInput
                          :floating="false"
                          name="areaMax"
                          placeholder="m2"
                          label=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="inner-spacing">
                  <span class="h-4">{{ t('price') }}</span>
                  <div class="row mt-4">
                    <div class="col-6">
                      <span class="h-5">{{ t('price_from') }}</span>
                      <div class="p-fluid">
                        <NumberInput
                          :floating="false"
                          name="priceMin"
                          :placeholder="currencyDecimal"
                          label=""
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <span class="h-5">{{ t('price_to') }}</span>
                      <div class="p-fluid">
                        <NumberInput
                          :floating="false"
                          name="priceMax"
                          :placeholder="currencyDecimal"
                          label=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section class="form-section">
                <div class="inner-spacing">
                  <span class="h-4">{{ t('clients.form.market') }}</span>
                  <div class="mt-3">
                    <SelectSingleButton
                      :options="marketOptions"
                      optionLabel="name"
                      optionValue="value"
                      label=""
                      name="market"
                      whiteBackground
                    />
                  </div>
                </div>
                <div class="inner-spacing">
                  <span class="h-4">
                    {{ t('clients.form.additional_data') }}
                  </span>
                  <div class="row">
                    <div class="col">
                      <TextareaInput
                        name="note"
                        label=""
                        :placeholder="t('clients.form.describe_client')"
                        rows="12"
                      />
                    </div>
                  </div>
                </div>
              </section>
              <div class="d-md-none">
                <Button
                  :disabled="isLoading"
                  type="submit"
                  class="p-button-primary w-100 px-4"
                  :label="t('clients.add')"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </PageContent>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import {
  TextInput,
  NumberInput,
  PageContent,
  TextareaInput,
  SelectSingleButton,
  useRootData,
  clientValidationService,
} from '@bd/components'
import { useForm } from 'vee-validate'
import { addClientSchema } from '@bd/components/yup'
import { LOCALISATION_SETTINGS } from '@bd/agent/config'
import { useAppStore } from '@bd/agent/store'
import { CustomerDto } from '@bd/api'
import { useToast } from 'primevue/usetoast'
import { AddClientMessages } from '@bd/agent/config/toastMessages'

export default defineComponent({
  name: 'AddClient',
  components: {
    PageContent,
    TextInput,
    NumberInput,
    TextareaInput,
    SelectSingleButton,
  },
  setup() {
    const i18n = useI18n()
    const store = useAppStore()
    const router = useRouter()
    const { isLoading } = useRootData()
    const toast = useToast()

    const form = useForm<Partial<CustomerDto>>({
      validationSchema: addClientSchema as never,
    })

    const onSubmit = form.handleSubmit(async (form, context) => {
      try {
        await store.dispatch('clients/addClient', form as CustomerDto)
        await router.push({ name: 'Dashboard' })
        toast.add(AddClientMessages.saveSuccess)
      } catch (error) {
        const errorsData = clientValidationService.handleValidation(error)
        errorsData && context.setErrors(errorsData)
        toast.add(AddClientMessages.saveError)
      }
    })

    const marketOptions = [
      { name: i18n.t('clients.form.primary'), value: 'PRIMARY' },
      {
        name: i18n.t('clients.form.secondary'),
        value: 'SECONDARY',
      },
    ]

    return {
      ...i18n,
      ...LOCALISATION_SETTINGS,
      addClientSchema,
      onSubmit,
      marketOptions,
      isLoading,
    }
  },
})
</script>

<style lang="scss" scoped>
:deep(.custom-select) {
  .p-button {
    border: 1px solid $blue-haze;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 5px;
  }
  .p-button-label {
    font-size: 0.875rem;
    font-weight: 500;
  }
}

:deep(.custom-page-content) {
  background: $white;
  overflow-y: auto;
  height: calc(
    (var(--vh) * 100) - #{$mobile-navigation-height} - #{$mobile-header-height}
  ) !important;
  @include breakpoint-up(md) {
    height: 100% !important;
  }
}
.form-content {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
  @include breakpoint-up(lg) {
    max-width: 60%;
  }
  @include breakpoint-down(lg) {
    max-width: 90%;
  }
  @include breakpoint-down(md) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 45px;
  }
}

.form-header {
  height: 6rem;
  @include flex(space-between);
  @include breakpoint-down(md) {
    display: none;
  }
}

.form-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 15%;
  @include breakpoint-down(lg) {
    column-gap: 10%;
  }
  @include breakpoint-down(md) {
    grid-template-columns: 1fr;
  }
}

.inner-spacing {
  padding: 1.5rem 0;
}
</style>
